<template>
  <div>
    <div>
      <div class="mx-4 mt-7" v-if="user">
        <div class="text-center">
          <p class="text-xl">Account info</p>
        </div>
        <div class="p-2 mt-3">
          <div class="grid grid-cols-3 gap-4 mb-4 items-center">
            <div class="col-span-1 content-start">Name</div>
            <div class="col-span-2 text-white">
              {{ user.member_name }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 mb-4 items-center">
            <div class="col-span-1">URL</div>
            <div class="col-span-2 text-white">
              Rap.land/c/{{ user.slug }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 mb-4 items-center">
            <div class="col-span-1">Email</div>
            <div class="col-span-2 truncate text-white">
              {{ user.email }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 mb-4 items-center">
            <div class="col-span-1">Password</div>
            <div class="col-span-2 text-white"></div>
          </div>
          <div class="grid grid-cols-3 gap-4 mb-4 items-center">
            <div class="col-span-1 pt-1">State</div>
            <div class="col-span-2 text-white">
              {{ user.state[0].name }}
            </div>
          </div>
          <div class="mt-8 mb-4 text-right">
            <router-link
              :to="{ name: 'TopRappers'}" 
              class="bg-btn rap-btn"
            >
              Done
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountInfo",
  computed: {
    user() {
      return this.$store.getters['User/user'];
    },
  },
};
</script>
